export const getBoardNumbers = (size: number) : number[] => {
  const board : number[] = [];
  const sizeSquare = size * size;

  // generate base sudoku
  for (let i = 0; i < sizeSquare; i++) {
    for (let j = 0; j < sizeSquare; j++) {
      board[i * sizeSquare + j] = 1 + (i * size + Math.floor(i / size) + j) % sizeSquare;
    }
  }
  const possibleNumbers : number[] = [];
  for (let i = 0; i < size * size; i++) {
    possibleNumbers.push(i + 1);
  }

  // swap boxes TODO later ?

  // swap columns from outside of same group
  for (let c = 0; c < size; c++) {
    for (let i = 0; i < size; i++) {
      const nc = Math.floor(Math.random() * size);
      const c1i = Math.floor(Math.random() * size) * size + nc;
      const c2i = Math.floor(Math.random() * size) * size + nc;
      for (let ri = 0; ri < sizeSquare; ri++) {
        const n1i = c1i + sizeSquare * ri;
        const n2i = c2i + sizeSquare * ri;
        const n1 = board[n1i];
        const n2 = board[n2i];
        board[n1i] = n2;
        board[n2i] = n1;
      }
    }
  }

  // swap numbers
  for (let i = 0; i < sizeSquare; i++) {
    const possibleN = [...possibleNumbers];
    const n1i = Math.floor(Math.random() * possibleN.length);
    const n1 = possibleN[n1i];
    possibleN.splice(n1i, 1);
    const n2i = Math.floor(Math.random() * possibleN.length);
    const n2 = possibleN[n2i];
    possibleN.splice(n2i, 1);
    const n3i = Math.floor(Math.random() * possibleN.length);
    const n3 = possibleN[n3i];
    possibleN.splice(n3i, 1);
    for (let j = 0; j < board.length; j++) {
      if (board[j] === n2) {
        board[j] = n1;
      } else if (board[j] === n3) {
        board[j] = n2;
      } else if (board[j] === n1) {
        board[j] = n3;
      }
    }
  }

  for (let c = 0; c < size; c++) {
    for (let bi = 0; bi < size; bi++) {
      // swap columns inside same group
      const c1i = Math.floor(Math.random() * size) + size * bi;
      const c2i = Math.floor(Math.random() * size) + size * bi;
      for (let i = 0; i < sizeSquare; i++) {
        const n1i = c1i + i * sizeSquare;
        const n1 = board[n1i];
        const n2i = c2i + i * sizeSquare;
        const n2 = board[n2i];
        board[n1i] = n2;
        board[n2i] = n1;
      }
      // swap columns inside same group
      const r1i = Math.floor(Math.random() * size) + size * bi;
      const r2i = Math.floor(Math.random() * size) + size * bi;
      for (let i = 0; i < sizeSquare; i++) {
        const n1i = i + r1i * sizeSquare;
        const n1 = board[n1i];
        const n2i = i + r2i * sizeSquare;
        const n2 = board[n2i];
        board[n1i] = n2;
        board[n2i] = n1;
      }
    }
  }
  return board;
};