import {selectTile} from 'actions/board';
import {ITile} from 'interfaces/tile.interface';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'reducers/rootReducer';
import './Board.scss';

export interface TileProps {
  Item: ITile,
  ClassName: string,
}

export const Tile = (props : TileProps) => {
  const dispatch = useDispatch();
  const {selectedTile} = useSelector((state: RootState) => ({
    selectedTile: state.board.selectedTile,
  }));

  const setTileSelected = (i: number) => {
    dispatch(selectTile(i));
  };

  let className = 'tile' + props.ClassName;
  if (selectedTile?.Id === props.Item.Id) {
    className += ' selected';
  }
  if (props.Item.IsOriginal == true) {
    className += ' original';
  } else {
    className += ' changeable';
  }
  if (selectedTile?.Id != props.Item.Id &&
    (selectedTile?.BoxId === props.Item.BoxId ||
      selectedTile?.RowId === props.Item.RowId ||
      selectedTile?.ColumnId === props.Item.ColumnId )) {
    className += ' related';
  }
  if (selectedTile?.Id != props.Item.Id &&
      selectedTile?.BoxId === props.Item.BoxId &&
      selectedTile?.RowId != props.Item.RowId &&
      selectedTile?.ColumnId != props.Item.ColumnId ) {
    className += ' box-related';
  }

  return <div className={className}
    data-index={props.Item.Id}
    data-box-index={props.Item.BoxId}
    data-row-index={props.Item.RowId}
    data-column-index={props.Item.ColumnId}
    onClick={() => setTileSelected(props.Item.IsOriginal ? 0 : props.Item.Id)}>{props.Item.Value}</div>;
};