// import { Header } from './components/Header';
import './App.scss';
import {Board} from './components/Board';

export const App = () => {
  return (
    <div className="container">
      <div className="navbar">
        <div className="navbar-center">
          <div className="navbar-logo">PlaySudoku<span className='blue-text'>.</span>eu</div>
        </div>
      </div>
      <div className="board-container">
        <Board />
      </div>
    </div>
  );
};