import React from 'react';
import ReactDOM from 'react-dom';
import {App} from './App';
import {Provider} from 'react-redux';
import {store} from './store';
import './index.scss';

import ReactGA from 'react-ga';
ReactGA.initialize('G-S5NVWE8W9B');
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);