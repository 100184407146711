import {ITile} from 'interfaces/tile.interface';
import {SELECT_TILE, BoardAction, SET_TILES, SET_VALUE} from '../actions/board';

export type BoardState = {
  selectedTile?: ITile;
  tiles: ITile[];
};

const initial: BoardState = {
  selectedTile: undefined,
  tiles: [],
};

export const boardReducer = (state = initial, action: BoardAction): BoardState => {
  switch (action.type) {
    case SELECT_TILE:
      return {...state, selectedTile: state.tiles.find((t) => t.Id === action.index)};
    case SET_TILES:
      return {...state, tiles: action.tiles};
    case SET_VALUE:
      const updatedTiles = state.tiles.map((tile)=>{
        if (tile.Id === state.selectedTile?.Id) {
          return {...tile, Value: action.key};
        } else {
          return tile;
        }
      });
      return {...state, tiles: updatedTiles};
    default:
      return state;
  }
};