import {ITile} from 'interfaces/tile.interface';

export const SELECT_TILE = 'SELECT_TILE';
export const SET_TILES = 'SET_TILES';
export const SET_VALUE = 'SET_VALUE';

type SelectTile = {
  type: typeof SELECT_TILE;
  index: number;
};

type SetTiles = {
  type: typeof SET_TILES;
  tiles: ITile[];
};

type SetValue = {
  type: typeof SET_VALUE;
  key: number;
};

export type BoardAction = SelectTile | SetTiles | SetValue;

export const selectTile = (index: number) => ({type: SELECT_TILE, index});
export const setTiles = (tiles: ITile[]) => ({type: SET_TILES, tiles});
export const setValue = (key: number) => ({type: SET_VALUE, key});