import {createStore, StoreEnhancer} from 'redux';
import {rootReducer} from './reducers/rootReducer';

type WithReduxDevtools = {
  __REDUX_DEVTOOLS_EXTENSION__: () => StoreEnhancer;
};

const windowWithReduxDevltools = (window as unknown) as WithReduxDevtools;

export const store = createStore(
    rootReducer,
    windowWithReduxDevltools.__REDUX_DEVTOOLS_EXTENSION__ &&
    windowWithReduxDevltools.__REDUX_DEVTOOLS_EXTENSION__(),
);