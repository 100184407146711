import {setTiles, setValue} from 'actions/board';
import {ITile} from 'interfaces/tile.interface';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'reducers/rootReducer';
import {getBoardNumbers} from 'services/numbers.service';
import './Board.scss';
import {Tile} from './Tile';

export const Board = () => {
  const dispatch = useDispatch();
  const {tiles} = useSelector((state: RootState) => ({
    tiles: state.board.tiles,
  }));

  const BOARD_SIZE = 3;
  const BOARD_SQUARE = BOARD_SIZE * BOARD_SIZE;

  // const COLORS = ['#1DB9C3'];

  useEffect(() => {
    function handleKeyPress(e: any) {
      if (0 < e.key && e.key <= BOARD_SQUARE) {
        dispatch(setValue(e.key));
      } else {
        console.log('wrong key');
      }
    }

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);


  // const NUMBER_OF_NUMBERS = BOARD_SQUARE * BOARD_SQUARE;
  // hard .25
  if (tiles.length === 0) {
    const boardNumbers = getBoardNumbers(BOARD_SIZE);
    const testNumbers: ITile[] = [];
    for (let i = 1; i <= boardNumbers.length; i++) {
      const rowId = (Math.floor((i - 1) / BOARD_SQUARE)) + 1;
      const columnId = (i % BOARD_SQUARE) === 0 ? BOARD_SQUARE : i % BOARD_SQUARE;
      const fromRowGroup = Math.floor((rowId - 1) / BOARD_SIZE);
      const fromColumnGroup = Math.floor((columnId - 1) / BOARD_SIZE) + 1;
      const boxId = fromRowGroup * BOARD_SIZE + fromColumnGroup;
      const isOriginal = Math.random() < 0.25;
      const tile: ITile = {
        Value: isOriginal ? boardNumbers[i - 1] : undefined, // TODO fix index (to contain 0), need to change select method
        Id: i,
        RowId: rowId,
        ColumnId: columnId,
        BoxId: boxId,
        IsOriginal: isOriginal,
      };

      testNumbers.push(tile);
    }
    dispatch(setTiles(testNumbers));
  }

  return (
    <div className='board'>
      {tiles.map((item, i) => {
        let className = '';
        const index = i + 1;

        if ((index - 1) % BOARD_SIZE == 0) {
          className += ' b-l-b';
        } else {
          className += ' b-l-l';
        }

        if (index % BOARD_SQUARE == 0) {
          className += ' b-r-b';
        }

        for (let i = 1; i <= BOARD_SIZE; i++) {
          if (index > i * BOARD_SQUARE * BOARD_SIZE && index <= i * BOARD_SQUARE * BOARD_SIZE + BOARD_SQUARE) {
            className += ' b-t-b';
          }
        }

        if (index <= BOARD_SQUARE) {
          className += ' b-t-b';
        } else {
          className += ' b-t-l';
        }

        if (index > BOARD_SQUARE * BOARD_SQUARE - BOARD_SQUARE) {
          className += ' b-b-b';
        }

        return <Tile key={'tile_key_' + i} ClassName={className} Item={item} />;
      })}
    </div>
  );
};